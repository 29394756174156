
import Vue from "vue";
import { importSalesOrders } from "@/modules/salesOrder/api/exportImport";
import { mapGetters } from "vuex";
import DropUpload from "@/components/DropUpload.vue";
import eventBus from "@/application/eventBus";

type DropFile = File & { isLoading: boolean };

interface ComponentData {
  stepper: 1 | 2 | 3;
  filesToImport: DropFile[];
  failedIds: number[];
}

export default Vue.extend({
  name: "ImportDialog",
  components: { DropUpload },
  data: (): ComponentData => ({
    stepper: 1,
    filesToImport: [],
    failedIds: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client"]),
  },
  watch: {
    "$attrs.value"(value) {
      if (!value) {
        this.stepper = 1;
        this.filesToImport = [];
        this.failedIds = [];
      }
    },
    filesToImport: {
      async handler(files) {
        if (files.length > 0 && this.stepper === 1) {
          this.filesToImport[0].isLoading = true;
          try {
            const response = await importSalesOrders(this.client.id, files[0]);
            this.failedIds = response.data.data.failed;
            this.stepper = 2;
            this.filesToImport[0].isLoading = false;

            this.$emit("failed", this.failedIds);
          } catch (e) {
            eventBus.$emit("snackbar", {
              text: this.$t("global.error"),
              color: "error",
            });
          } finally {
            this.$emit("finished");
          }
        }
      },
      deep: true,
    },
  },
});
