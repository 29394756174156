import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface ConceptSalesOrderIndexItem {
  id: number;
  clientId: number;
  clientName: string;
  orderDate?: string;
  orderNumber?: string;
  companyName?: string;
  fullName?: string;
  city?: string;
  country?: string;
  originalSource: string;
  errors?: string;
}

export const clientConceptSalesOrderIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<ConceptSalesOrderIndexItem[]>>> => {
  const response = await getPaginated(
    `client/${clientId}/sales-order/concept`,
    data
  );
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};

export const conceptSalesOrderIndex = async (
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<ConceptSalesOrderIndexItem[]>>> => {
  const response = await getPaginated("sales-order/concept", data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
